<template>
    <div class="inner">
        <div class="d-flex justify-content-between">
            <h4>{{ title }}</h4>
            <h5 class="balanceBtn" @click="setMaxBalance()">Balance: {{balanceValue}}</h5>
        </div>
        <div class="sol d-flex">
            <div  @click="selection(index)" class="swapTokenBtn d-flex align-items-center" style="cursor:pointer">
                <span class="avatar">
                    <img :src="coin.logo" alt="">
                </span>
                <h3 class="tokenName">{{ coin.symbol }}</h3>
                <i>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" style="enable-background:new 0 0 284.929 284.929;"
                    xml:space="preserve">
                    <g>
                        <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                            L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                            c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                            c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
                    </g>
                    </svg>
                </i>
            </div>
            <div v-if = "getCompareBalance" class="align-items-center crypto">
                <q-input :class="['input-text',focused?'focused':'']" style="padding:0 10px;"
                    standout="bg-indigo-5" type="text" placeholder="0.00" :disable="readonly" 
                    @input.native="updateValue" v-model="mutableValue"
                    :error-message="errorMessage" :error="errorMessage!=null" :bottom-slots="false" :hide-bottom-space="true">
                    <template v-slot:prepend>
                        <div class="dropdown">
                            <button v-if="getIndex == '0'" @click="showPercent()" 
                                class="dropbtn1 mx-2 dropdown-toggle">
                                {{currentPercent}}%</button>
                            <button v-if="getIndex == '1'" @click="showPercent()" 
                                class="dropbtn2 mx-2 dropdown-toggle">
                                {{currentPercent}}%</button>

                            <div v-if="getIndex == '0'" id="percent1" class="percent dropdown-content1" 
                                v-click-outside="onClickOutside1">
                                <ul>
                                    <li @click="setPercent(0)">25%</li>
                                    <li @click="setPercent(1)">50%</li>
                                    <li @click="setPercent(2)">75%</li>
                                    <li @click="setPercent(3)">100%</li>
                                </ul>
                            </div>
                            <div v-if="getIndex == '1'" id="percent2" class="percent dropdown-content2" 
                                v-click-outside="onClickOutside1">
                                <ul>
                                    <li @click="setPercent(0)">25%</li>
                                    <li @click="setPercent(1)">50%</li>
                                    <li @click="setPercent(2)">75%</li>
                                    <li @click="setPercent(3)">100%</li>
                                </ul>
                            </div>
                        </div>
                    </template>
                </q-input>
            </div>
            <div v-if = "!getCompareBalance" class="align-items-center crypto unMax">
                <q-input :class="['input-text',focused?'focused':'']" style="padding:0 10px;"
                    standout="bg-indigo-5" type="text" placeholder="0.00" :disable="readonly" 
                    @input.native="updateValue" v-model="mutableValue"
                    :error-message="errorMessage" :error="errorMessage!=null" 
                    :bottom-slots="false" :hide-bottom-space="true">
                </q-input>
            </div>
        </div>
        <div v-if="showModal" class="overlay" id="tokenPop">
            <aside v-click-outside="onClickOutside">
                <h2>Select a token</h2>
                <div class="close swapTokenBtn text-white" style="margin:0; padding:0">
                    <q-btn icon="close" round flat dense @click="showModal = !showModal"/>
                </div>
                <!-- <form action="" class="mb-3">
                    <input type="search" placeholder="Search name or mint address" class="form-control" />
                </form> -->
                <hr />
                <div class="d-flex justify-content-between align-items-center q-px-md">
                    <h4>Token</h4>
                    <h4 class="q-mr-md">Balance</h4>
                </div>
                <div class="tokenList">
                    <div class="tokenBtn row" v-for="(item, index) in dataList" :key="`list${index}`"
                            @click="changeCoin(item)">
                        <i>
                            <img :src="item.logo" alt="">
                        </i>
                        <h3 class="col-4" style="padding-left:0; padding-right:0">{{item.symbol}}<br /><small>{{item.name}}</small></h3>
                        <h3 class="col-6" style="text-align:right; margin-left:5px;padding:0">{{getTokenBalance(index)}}</h3>
                    </div>
                </div>
                <hr>
            </aside>
        </div>
    </div>
</template>
<script>
    import BigNumber from 'bignumber.js'
    import vClickOutside from 'v-click-outside'

    export default {
        data() {
            return {
                selectedCoin: null,
                showModal: false,
                percents: [
                    '25', '50', '75', '100'
                ],
                currentPercent: 100
            };
        },
        props: {
            title:{type: String},
            value:null,
            coin:{type:Object,default:()=>{}},
            dataList: {
                type: Array,
                default: () => []
            },
            readonly: {type:Boolean,default:false},
            focused: {type:Boolean,default:false},
            max:null,
            balance:null,
            compareBalance:null,
            index:{type: String},
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        mounted() {
        },
        computed: {
            getIndex() {
                return this.index;
            },
            getCompareBalance() {
                return this.compareBalance;
            },
            errorMessage() {
                if (!this.compareBalance)
                    return null;
                if(BigNumber(this.value).isGreaterThan(this.max)) {
                    return "INSUFFICIENT ACCOUNT BALANCE";
                }
                return null;
            },
            mutableValue: {
                get: function() {
                    return this.value;
                },
                set: function(value) {
                    this.$emit('input',value);
                }
            },
            balanceValue() {
                if(this.balance > 0)
                    return this.getDecimalData(BigNumber(this.balance));

                return '0';
            }
        },
        methods: {
            showPercent() {
                if (this.index == 0) {
                    document.getElementById("percent1").classList.toggle("show");
                }
                else if (this.index == 1) {
                    document.getElementById("percent2").classList.toggle("show");
                }
            },
            setMaxBalance() {
                if (!this.compareBalance)
                    return;

                this.setPercent(3);
            },
            setPercent(value) {
                this.currentPercent = this.percents[value];

                if (value == 0)
                    this.mutableValue = BigNumber(this.max).div(4);
                else if (value == 1)
                    this.mutableValue = BigNumber(this.max).div(2);
                else if (value == 2)
                    this.mutableValue = BigNumber(this.max).times(3).div(4);
                else
                    this.mutableValue = BigNumber(this.max);

                if (this.mutableValue == '0')
                    this.mutableValue = '0.00';

                if (this.index == '0')
                    document.getElementById("percent1").classList.toggle("show");
                else if (this.index == '1')
                    document.getElementById("percent2").classList.toggle("show");

            },
            onClickOutside (event) {
                this.showModal = false;
            },
            onClickOutside1 (event) {
                if (this.index == '0') {
                    if (!event.target.matches('.dropbtn1')) {
                        let dropdowns = document.getElementsByClassName("dropdown-content1");
                        let i;
                        for (i = 0; i < dropdowns.length; i++) {
                            let openDropdown = dropdowns[i];
                            if (openDropdown.classList.contains('show')) {
                                openDropdown.classList.remove('show');
                            }
                        }
                    }
                }
                else if (this.index == '1') {
                    if (!event.target.matches('.dropbtn2')) {
                        let dropdowns = document.getElementsByClassName("dropdown-content2");
                        let i;
                        for (i = 0; i < dropdowns.length; i++) {
                            let openDropdown = dropdowns[i];
                            if (openDropdown.classList.contains('show')) {
                                openDropdown.classList.remove('show');
                            }
                        }
                    }
                }
            },
            selection() {
                this.showModal = !this.showModal;
            },
            changeCoin(item) {
                this.$emit('change', item);
                this.showModal = false;
            },
            updateValue(e) {
                if(this.errorMessage==null)
                    this.$emit('input',e.target.value);
            },
            getTokenBalance(index) {
                let decimals = this.$store.state.liquidity.tokenDecimals[index] * (-1);

                if (this.$store.state.liquidity.tokenBalance[index] > 0) {
                    let value = BigNumber(this.$store.state.liquidity.tokenBalance[index])
                            .shiftedBy(decimals);

                    return this.getDecimalData(value);
                }

                return '0.00';
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            }
        }
    };
</script>

<style scoped>
    input.token {
        color:#abc4ed; 
        height:35px; 
        width:68%; 
        background:transparent; 
        border: 1px solid #606aa0 !important;
        border-radius: 5px;
        margin-left:5px;
        padding:0 10px;
        font-size: 14px;
    }

    .input-text {
        width: 100%;
        font-weight: 700;
        /* padding: 10px; */
        /* border-radius: 10px; */
        font-size: 1.5rem;
        color: rgb(249 249 249 / 87%);
        background-color:transparent;
        /* background-color: var(--q-color-dark); */
        border: none;
    }

    .crypto {
        background: none;
    }

    .crypto >>> .q-field__control {
        /* background: none; */
    }

    .crypto >>> .q-field {
        border-radius:5px;
    }

    .crypto >>> .q-field__native{
        color: rgb(249 249 249 / 87%);
        padding-right:10px;
    }

    .crypto .row {
        padding:0 !important;
        margin:0 !important;
    }

    .crypto >>> .q-field .row {
        padding:0 !important;
        margin:0 !important;
    }

    .crypto >>> .q-field .col {
        padding:0 !important;
        margin:0 !important;
    }

    .close >>> .row {
        padding:0 !important;
        margin:0 !important;
    }

    .close >>> .col {
        padding:0 !important;
        margin:0 !important;
    }

    .unMax {
        width:100% !important;
        /* padding-left: 70px; */
    }

    .unMax >>> input {
        margin-left: 70px !important;
    }

    .tokenBtn >>> .col {
        padding: 0 !important;
    }
</style>