<template>
    <div>
        <div id="tabSec">
            <div class="balance" >
                <CurrencyField title="From" :coin="coinOne" :dataList="tokenList" :focused="baseCoin==1"
                    :readonly="coinOne.name==undefined || coinTwo.name==undefined?true:false"
                    @change="changeOne" @input="inputOne" v-model="valueOne" :max="balanceOne" :balance="balanceOne" 
                    :compareBalance="true" index="0" />
                <div>
                    <span class="swap my-2"  style="cursor:pointer">
                        <svg @click="inverse" xmlns="http://www.w3.org/2000/svg" 
                            width="24" height="24" viewBox="0 0 24 24">
                            <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"/>
                        </svg>
                    </span>
                </div>
                <CurrencyField title="To" :coin="coinTwo" :dataList="tokenList" :focused="baseCoin==2"
                    :readonly="coinOne.name==undefined || coinTwo.name==undefined?true:false"
                    @change="changeTwo" @input="inputTwo" v-model="valueTwo" :max="balanceTwo" :balance="balanceTwo" 
                    :compareBalance="false" index="1" class="q-mb-md" />
                <div class="transition q-my-md">
                    <div class="q-px-md q-py-md">
                        <div class="d-flex justify-content-between">
                            <h2>Exchange Rate</h2>
                            <h2>
                                1 {{coinOne.symbol}} = {{getRateOne2Two}} {{coinTwo.symbol}}
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="walletBtn" v-if="!isMetaMaskConnected" @click="connectWallet">Connect Wallet</div>
                <div class="walletBtn" v-if="isMetaMaskConnected && !isApproved" @click="approve">Approve</div>
                <div class="walletBtn" v-if="isMetaMaskConnected && isApproved" 
                    @click="swap" :disabled="!isLiquidityExist">Swap</div>
                <div id="extraInfo" class="inner transition q-mt-md">
                    <div class="q-px-sm q-pt-sm">
                        <div class="d-flex justify-content-between">
                            <h2>{{getLimitText}}
                                <div class="tooltip1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                    </svg>
                                    <span class="tooltiptext">
                                        <p class="note">
                                            Your transaction will revert <br>if there is a large,<br> unfavorable price<br> movement before it is<br> confirmed.
                                        </p>
                                    </span>
                                </div>
                            </h2>
                            <h2 class="text-white">
                                {{getLimitValue}} <span class="token" style="font-size:0.8rem">{{ getExpectedToken }}</span>
                            </h2>
                        </div>
                    </div>
                    <div class="q-px-sm">
                        <div class="d-flex justify-content-between">
                            <h2>Price Impact
                                <div class="tooltip1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                    </svg>
                                    <span class="tooltiptext kind1">
                                        <p class="note">
                                            The difference between the market price and estimated price due to trade size.
                                        </p>
                                    </span>
                                </div>
                            </h2>
                            <h2 class="text-white">
                                <span v-if="!warningPriceImpact" class="text-green" style="font-size:0.9rem">&lt;{{getPriceImpact()}}%</span>
                                <span v-if="warningPriceImpact" class="text-red" style="font-size:0.9rem">&lt;{{getPriceImpact()}}%</span>
                            </h2>
                        </div>
                    </div>
                    <div class="q-px-sm">
                        <div class="d-flex justify-content-between">
                            <h2>Liquidity Provider Fee
                                <div class="tooltip1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                    </svg>
                                    <span class="tooltiptext">
                                        <p class="note">
                                            For each trade a 0.3% fee is paid. 0.15% goes to liquidity providers and 0.15% goes to the StarCards NFT holders.
                                        </p>
                                    </span>
                                </div>
                            </h2>
                            <h2 class="text-white">
                                {{getFee}} <span class="token" style="font-size:0.8rem">{{ coinOne.symbol }}</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div v-if="isOneCrossRouter()" class="transition q-mt-md" style="padding-top:0;">
                    <div class="q-px-md">
                        <div class="justify-content-between">
                            <div class="row q-py-md q-px-md crossBox">
                                <div class="col-md-3 col-sm-12" style="padding:0">
                                    <span class="text-green">Route&nbsp;</span>
                                    <div class="tooltip1" style="margin-bottom:2px">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                        </svg>
                                        <span class="tooltiptext kind1">
                                            <p class="note">
                                                Routing through these tokens resulted in the best price for your trade.
                                            </p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-9 col-sm-12 row q-pl-md" style="padding-right:0;">
                                    <div class="col-4 flex" style="padding:0">
                                        <span>
                                            <img :src="this.coinOne.logo">
                                        </span>
                                        <span style="padding-top:1px;padding-left:5px">
                                            {{this.coinOne.symbol}}
                                        </span>
                                        <!-- <span>►</span> -->
                                    </div>
                                    <div class="col-4 flex" style="padding:0">
                                         <span>
                                            <img :src="this.coinOneCross.logo">
                                        </span>
                                        <span style="padding-top:1px;padding-left:5px">
                                            {{this.coinOneCross.symbol}}
                                        </span>
                                        <!-- <span>►</span> -->
                                    </div>
                                    <div class="col-3 flex" style="padding:0">
                                        <span>
                                            <img :src="this.coinTwo.logo">
                                        </span>
                                        <span style="padding-top:1px;padding-left:5px">
                                            {{this.coinTwo.symbol}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isTwoCrossRouter()" class="transition q-mt-md" style="padding-top:0;">
                    <div class="q-px-md">
                        <div class="justify-content-between">
                            <div class="row q-py-md q-px-md crossBox" style="padding-right:0">
                                <div class="col-md-2 col-sm-12" style="padding:0">
                                    <span class="text-green">Route&nbsp;</span>
                                    <div class="tooltip1" style="margin-bottom:2px">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                        </svg>
                                        <span class="tooltiptext kind1">
                                            <p class="note">
                                                Routing through these tokens resulted in the best price for your trade.
                                            </p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-10 col-sm-12 row" style="padding-right:0;">
                                    <div class="col-3 flex" style="padding:0">
                                        <span class="q-pl-md">
                                            <img :src="this.coinOne.logo">
                                        </span>
                                        <span style="padding-top:1px;padding-left:5px">
                                            {{this.coinOne.symbol}}
                                        </span>
                                    </div>
                                    <div class="col-3 flex" style="padding:0">
                                         <span class="q-pl-md">
                                            <img :src="this.coinOneCross.logo">
                                        </span>
                                        <span style="padding-top:1px;padding-left:5px">
                                            {{this.coinOneCross.symbol}}
                                        </span>
                                    </div>
                                    <div class="col-3 flex" style="padding:0">
                                         <span class="q-pl-md">
                                            <img :src="this.coinTwoCross.logo">
                                        </span>
                                        <span style="padding-top:1px;padding-left:5px">
                                            {{this.coinTwoCross.symbol}}
                                        </span>
                                    </div>
                                    <div class="col-3 flex" style="padding:0">
                                        <span class="q-pl-md">
                                            <img :src="this.coinTwo.logo">
                                        </span>
                                        <span style="padding-top:1px;padding-left:5px">
                                            {{this.coinTwo.symbol}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="price">
            <div class="row align-items-center q-pl-sm">
                <div class="col-2" style="padding-left:15px; padding-right:0">
                    <span>
                        <img :src="this.coinOne.logo" alt="">
                    </span>
                    <h3 class="mt-1">{{this.coinOne.symbol}}</h3>
                </div>
                <div class="col-3" style="padding-left:15px; padding-right:0">
                    <h2><small>Price</small><br>{{ formatPrice(0) }}</h2>
                </div>
                <div class="col-2" style="padding-left:0; padding-right:0">
                    <h2 v-if="format24hPrice(0) > 0" class="green">
                        <small>24H</small><br>{{format24hPrice(0)}} %</h2>
                    <h2 v-if="format24hPrice(0) <= 0" class="pink">
                        <small>24H</small><br>{{format24hPrice(0)}} %</h2>
                </div>
                <div class="col-4 text-right q-mt-sm" style="padding-left:0; padding-right:0">
                    <img class="chart" v-if ="this.chartURL0 != ''"
                        :src="getChartURL(0)">
                    <img class="chart" v-if ="this.chartURL0 == ''"
                        src="@/assets/images/sparkline.svg">                   
                </div>
            </div>
            <div class="row align-items-center my-3 q-pl-sm">
                <div class="col-2" style="padding-left:15px; padding-right:0">
                    <span>
                        <img :src="this.coinTwo.logo" alt="">
                    </span>
                    <h3 class="mt-1">{{this.coinTwo.symbol}}</h3>
                </div>
                <div class="col-3" style="padding-left:15px; padding-right:0">
                    <h2><small>Price</small><br>{{ formatPrice(1) }}</h2>
                </div>
                <div class="col-2" style="padding-left:0; padding-right:0">
                    <h2 v-if="format24hPrice(1) > 0" class="green">
                        <small>24H</small><br>{{format24hPrice(1)}} %</h2>
                    <h2 v-if="format24hPrice(1) <= 0" class="pink">
                        <small>24H</small><br>{{format24hPrice(1)}} %</h2>
                </div>
                <div class="col-4 text-right q-mt-sm" style="padding-left:0; padding-right:0">
                    <img class="chart" v-if ="this.chartURL1 != ''"
                        :src="getChartURL(1)">
                    <img class="chart" v-if ="this.chartURL1 == ''"
                        src="@/assets/images/sparkline.svg">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CurrencyField from '@/components/currency/CurrencyField'
    import { BigNumber } from 'bignumber.js'
    import {token_list} from '@/constants/token.js'
    import axios from 'axios'

    export default {
        components: {
            CurrencyField,
        },
        props: {
            token0: null,
            token1: null
        },
        data() {
            return {
                coinOne: {},
                coinTwo: {},
                coinOneCross: {},
                coinTwoCross: {},
                valueOne: '',
                valueTwo: '',
                baseCoin: 0,
                tokenList: [],
                localData0: [],
                localData1: [],
                chartURL0: null,
                chartURL1: null,
                localLoading: true,
                updateInterval: null,
                liquidityFee: null,
                limitValue:'',
                limitText: '',
                expectedToken: null,
                warningPriceImpact: false,
                marketPrice: [],
            }
        },
        computed: {
            isMetaMaskConnected() {
                return this.$store.state.account!=null;
            },
            balanceOne() {
                if (this.coinOne.symbol) {
                    let decimals = this.coinOne.decimals * (-1);

                    return BigNumber(this.$store.state.balance.amountFrom).shiftedBy(decimals);
                }
                else {
                    return BigNumber(0);
                }
            },
            balanceTwo() {
                if (this.coinTwo.symbol) {
                    let decimals = this.coinTwo.decimals * (-1);
                    return BigNumber(this.$store.state.balance.amountTo).shiftedBy(decimals);
                }
                else {
                    return BigNumber(0);
                }
            },
            isApproved() {
                return this.$store.state.approvedOneTokenRouter;
            },
            getRateOne2Two() {
                if ((this.coinOne.symbol == "ASTR" && this.coinTwo.symbol == "WASTR") || 
                     this.coinOne.symbol == "WASTR" && this.coinTwo.symbol == "ASTR") {
                    return 1;
                }

                if(this.$store.state.liquidity.rateOne2Two > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.liquidity.rateOne2Two));

                return 0;
            },
            isLiquidityExist() {
                if ((this.coinOne.symbol == "ASTR" && this.coinTwo.symbol == "WASTR") 
                    || (this.coinOne.symbol == "WASTR" && this.coinTwo.symbol == "ASTR")) {
                    return true;
                }

                if (this.$store.state.liquidity.crossOneRouter)
                    return true;

                if (this.$store.state.liquidity.crossTwoRouter)
                    return true;

                if (this.$store.state.liquidity.totalSupply > 0) {
                    return true;
                }

                return false;

            },
            getFee() {
                return this.liquidityFee;
            },
            getLimitText() {
                return this.limitText;
            },
            getLimitValue() {
                return this.limitValue;
            },
            getExpectedToken() {
                return this.expectedToken;
            },
        },
        mounted() {
            this.tokenList = token_list;

            this.coinOne = this.$store.state.liquidity.tokenMap.get(this.token0);
            this.coinTwo = this.$store.state.liquidity.tokenMap.get(this.token1);

            this.$store.dispatch("changeTokenProcess",
                {
                    coinOne: this.coinOne,
                    coinTwo: this.coinTwo,
                    decimalChanged: false
                }
            );

            this.getCoinInfo(0, this.coinOne.coingeckoid);
            this.chartURL0 = this.coinOne.coingeckochartid;

            this.getCoinInfo(1, this.coinTwo.coingeckoid);
            this.chartURL1 = this.coinTwo.coingeckochartid;

            this.updateInterval = setInterval(()=>{
                this.updateValues();
            }, 15000)
        },
        destroyed() {
            clearInterval(this.updateInterval);
        },
        methods: {
            connectWallet() {
                this.$store.dispatch('connect');
            },
            isOneCrossRouter() {
                if (this.$store.state.liquidity.crossOneRouter)
                    this.coinOneCross = this.$store.state.liquidity.tokenMap.get(this.$store.state.liquidity.crossOneTokenSymbol);

                return this.$store.state.liquidity.crossOneRouter;
            },
            isTwoCrossRouter() {
                if (this.$store.state.liquidity.crossTwoRouter) {
                    this.coinOneCross = this.$store.state.liquidity.tokenMap.get(this.$store.state.liquidity.crossOneTokenSymbol);
                    this.coinTwoCross = this.$store.state.liquidity.tokenMap.get(this.$store.state.liquidity.crossTwoTokenSymbol);
                }

                return this.$store.state.liquidity.crossTwoRouter;
            },
            changeOne(item) {
                if (this.coinTwo === item) {
                    this.inverse();
                } else {
                    this.getCoinInfo(0, item.coingeckoid);
                    this.chartURL0 = item.coingeckochartid;
                    let decimalChanged;
                    if (this.coinOne.decimals != item.decimals)
                        decimalChanged = true;
                    else
                        decimalChanged = false;
                    this.coinOne = item;
                    this.$store.dispatch("changeTokenProcess",
                        { 
                            coinOne: this.coinOne,
                            coinTwo: this.coinTwo,
                            decimalChanged: decimalChanged
                        }
                    ).then((ret)=>{
                        this.updateValues();
                    });
                }
            },
            changeTwo(item) {
                if (this.coinOne === item) {
                    this.inverse();
                } else {
                    this.getCoinInfo(1, item.coingeckoid);
                    this.chartURL1 = item.coingeckochartid;

                    let decimalChanged;
                    if (this.coinTwo.decimals != item.decimals)
                        decimalChanged = true;
                    else
                        decimalChanged = false;
                    this.coinTwo = item;
                    this.$store.dispatch("changeTokenProcess",
                        { 
                            coinOne: this.coinOne,
                            coinTwo: this.coinTwo,
                            decimalChanged: decimalChanged
                        }
                    ).then((ret)=>{
                        this.updateValues();
                    });
                }
            },
            inputOne(value) {
                if(parseFloat(value)>0) {
                    if ((this.coinOne.symbol == "ASTR" && this.coinTwo.symbol == "WASTR") 
                    || (this.coinOne.symbol == "WASTR" && this.coinTwo.symbol == "ASTR")) {
                        this.valueTwo = value;

                        this.hideBlock();

                        return;
                    }

                    if (this.$store.state.liquidity.crossOneRouter) {
                        this.baseCoin = 1;
                        let decimals = this.coinOne.decimals;
                        let params = {
                            baseCoin: 1,
                            amount: BigNumber(value).shiftedBy(decimals).integerValue().toString(),
                            coinIn: this.coinOne.address,
                            coinOut: this.$store.state.liquidity.crossOneTokenAddress
                        };
                        this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                            params = {
                                baseCoin: 1,
                                amount: BigNumber(ret).integerValue().toString(),
                                coinIn: this.$store.state.liquidity.crossOneTokenAddress,
                                coinOut: this.coinTwo.address
                            };
                            this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                                decimals = this.coinTwo.decimals * (-1);
                                this.valueTwo = this.getDecimalData(BigNumber(ret).shiftedBy(decimals));
                                let value = BigNumber(ret)
                                        .times(BigNumber(10000).minus(this.$store.state.settings.slippage)
                                        .div(10000)).shiftedBy(decimals);
                                this.limitValue = this.getDecimalData(value);
                                this.expectedToken = this.coinTwo.symbol;

                                this.$emit('set-fee',this.fee())
                            });
                        });

                        this.liquidityFee = this.getDecimalData(BigNumber(value).times(3).div(1000));
                    }
                    else if (this.$store.state.liquidity.crossTwoRouter) {
                        this.baseCoin = 1;
                        let decimals = this.coinOne.decimals;
                        let params = {
                            baseCoin: 1,
                            amount: BigNumber(value).shiftedBy(decimals).integerValue().toString(),
                            coinIn: this.coinOne.address,
                            coinOut: this.$store.state.liquidity.crossOneTokenAddress
                        };
                        this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                            params = {
                                baseCoin: 1,
                                amount: BigNumber(ret).integerValue().toString(),
                                coinIn: this.$store.state.liquidity.crossOneTokenAddress,
                                coinOut: this.$store.state.liquidity.crossTwoTokenAddress
                            };
                            this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                                params = {
                                    baseCoin: 1,
                                    amount: BigNumber(ret).integerValue().toString(),
                                    coinIn: this.$store.state.liquidity.crossTwoTokenAddress,
                                    coinOut: this.coinTwo.address
                                };
                                this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                                    decimals = this.coinTwo.decimals * (-1);
                                    this.valueTwo = this.getDecimalData(BigNumber(ret).shiftedBy(decimals));
                                    let value = BigNumber(ret)
                                            .times(BigNumber(10000).minus(this.$store.state.settings.slippage)
                                            .div(10000)).shiftedBy(decimals);
                                    this.limitValue = this.getDecimalData(value);
                                    this.expectedToken = this.coinTwo.symbol;

                                    this.$emit('set-fee',this.fee())
                                });
                            });
                        });

                        this.liquidityFee = this.getDecimalData(BigNumber(value).times(3).div(1000));
                    }
                    else {
                        this.baseCoin = 1;
                        let decimals = this.coinOne.decimals;
                        let params = {
                            baseCoin: 1,
                            amount: BigNumber(value).shiftedBy(decimals).integerValue().toString(),
                            coinIn: this.coinOne.address,
                            coinOut: this.coinTwo.address
                        };
                        this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                            decimals = this.coinTwo.decimals * (-1);
                            this.valueTwo = this.getDecimalData(BigNumber(ret).shiftedBy(decimals));
                            
                            let value = BigNumber(ret)
                                    .times(BigNumber(10000).minus(this.$store.state.settings.slippage)
                                    .div(10000)).shiftedBy(decimals);
                            this.limitValue = this.getDecimalData(value);
                            this.expectedToken = this.coinTwo.symbol;

                            this.$emit('set-fee',this.fee())
                        });

                        this.liquidityFee = this.getDecimalData(BigNumber(value).times(3).div(1000));
                    }

                    this.showBlock(0);
                } else {
                    this.hideBlock();
                    this.valueTwo = "";
                }
            },
            inputTwo(value) {
                if(parseFloat(value)>0) {
                    if ((this.coinOne.symbol == "ASTR" && this.coinTwo.symbol == "WASTR") 
                        || (this.coinOne.symbol == "WASTR" && this.coinTwo.symbol == "ASTR")) {
                        this.valueOne = value;

                        this.hideBlock();
                        return;
                    }

                    if (this.$store.state.liquidity.crossOneRouter) {
                        this.baseCoin = 2;
                        let decimals = this.coinTwo.decimals;
                        let params = {
                            baseCoin: 2,
                            amount: BigNumber(value).shiftedBy(decimals).integerValue().toString(),
                            coinIn: this.$store.state.liquidity.crossOneTokenAddress,
                            coinOut: this.coinTwo.address,
                        };
                        this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                            params = {
                                baseCoin: 2,
                                amount: BigNumber(ret).integerValue().toString(),
                                coinIn: this.coinOne.address,
                                coinOut: this.$store.state.liquidity.crossOneTokenAddress,
                            };

                            this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                                decimals = this.coinOne.decimals * (-1);
                                this.valueOne = this.getDecimalData(BigNumber(ret).shiftedBy(decimals));

                                let value = BigNumber(ret).times(10000)
                                        .div(BigNumber(10000).minus(this.$store.state.settings.slippage))
                                        .shiftedBy(decimals);

                                this.limitValue = this.getDecimalData(value);
                                this.liquidityFee = this.getDecimalData(BigNumber(ret).times(3).div(1000).shiftedBy(decimals));
                                this.expectedToken = this.coinOne.symbol;
                                this.$emit('set-fee',this.fee())
                            });
                        });

                        this.showBlock(1);
                    }
                    else if (this.$store.state.liquidity.crossTwoRouter) {
                        this.baseCoin = 2;
                        let decimals = this.coinTwo.decimals;
                        let params = {
                            baseCoin: 2,
                            amount: BigNumber(value).shiftedBy(decimals).integerValue().toString(),
                            coinIn: this.$store.state.liquidity.crossTwoTokenAddress,
                            coinOut: this.coinTwo.address,
                        };
                        this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                            params = {
                                baseCoin: 2,
                                amount: BigNumber(ret).integerValue().toString(),
                                coinIn: this.$store.state.liquidity.crossOneTokenAddress,
                                coinOut: this.$store.state.liquidity.crossTwoTokenAddress,
                            };
                            this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                                params = {
                                    baseCoin: 2,
                                    amount: BigNumber(ret).integerValue().toString(),
                                    coinIn: this.coinOne.address,
                                    coinOut: this.$store.state.liquidity.crossOneTokenAddress,
                                };

                                this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                                    decimals = this.coinOne.decimals * (-1);
                                    this.valueOne = this.getDecimalData(BigNumber(ret).shiftedBy(decimals));

                                    let value = BigNumber(ret).times(10000)
                                            .div(BigNumber(10000).minus(this.$store.state.settings.slippage))
                                            .shiftedBy(decimals);

                                    this.limitValue = this.getDecimalData(value);
                                    this.liquidityFee = this.getDecimalData(BigNumber(ret).times(3).div(1000).shiftedBy(decimals));
                                    this.expectedToken = this.coinOne.symbol;
                                    this.$emit('set-fee',this.fee())
                                });
                            });
                        });

                        this.showBlock(1);
                    }
                    else {
                        this.baseCoin = 2;
                        let decimals = this.coinTwo.decimals;
                        let params = {
                            baseCoin: 2,
                            amount: BigNumber(value).shiftedBy(decimals).integerValue().toString(),
                            coinIn: this.coinOne.address,
                            coinOut: this.coinTwo.address,
                        };
                        this.$store.dispatch('getAmountSwap',params).then((ret)=>{
                            decimals = this.coinOne.decimals * (-1);
                            this.valueOne = this.getDecimalData(BigNumber(ret).shiftedBy(decimals));

                            let value = BigNumber(ret).times(10000)
                                    .div(BigNumber(10000).minus(this.$store.state.settings.slippage))
                                    .shiftedBy(decimals);

                            this.limitValue = this.getDecimalData(value);
                            this.liquidityFee = this.getDecimalData(BigNumber(ret).times(3).div(1000).shiftedBy(decimals));
                            this.expectedToken = this.coinOne.symbol;
                            this.$emit('set-fee',this.fee())
                        });

                        this.showBlock(1);
                    }
                } else {
                    this.hideBlock();
                    this.valueOne = "";
                }
            },
            updateValues() {
                if (this.baseCoin == 1) {
                    let value = this.valueOne;
                    this.inputOne(value)
                }
                else if (this.baseCoin == 2) {
                    let value = this.valueTwo;
                    this.inputTwo(value)
                }
            },
            inverse() {
                let itemOne = this.coinOne;
                let valueOne = this.valueOne;
                this.coinOne = this.coinTwo;
                this.valueOne = this.valueTwo;
                this.coinTwo = itemOne;
                this.valueTwo = valueOne;
                if(this.coinOne.name && this.coinTwo.name) {
                    if(this.baseCoin==2) {
                        this.inputOne(this.valueOne);
                    } else if(this.baseCoin==1) {
                        this.inputTwo(this.valueTwo);
                    }
                }

                this.getCoinInfo(0, this.coinOne.coingeckoid);
                this.chartURL0 = this.coinOne.coingeckochartid;
                this.getCoinInfo(1, this.coinTwo.coingeckoid);
                this.chartURL1 = this.coinTwo.coingeckochartid;

                let decimalChanged;
                if (this.coinOne.decimals != this.coinTwo.decimals)
                    decimalChanged = true;
                else
                    decimalChanged = false;

                this.$store.dispatch("changeTokenProcess",
                    { 
                        coinOne: this.coinOne,
                        coinTwo: this.coinTwo,
                        decimalChanged: decimalChanged
                    }
                );
            },
            approve() {
                this.$store.dispatch('approveRouter', {
                    tokenAddress: this.coinOne.address,
                    index: 0
                });
            },
            swap() {
                this.$store.dispatch('swap',{
                    coinA: this.coinOne,
                    coinB: this.coinTwo,
                    amountA: this.valueOne,
                    amountB: this.valueTwo
                });
            },
            fee() {
                // if(this.coinOne.name=='STAR') {
                //     let fee = BigNumber(this.valueOne).times(0.01);
                //     return fee.toFixed(5);
                // } else if(this.coinTwo.name=='STAR') {
                //     let fee = BigNumber(this.valueTwo).times(0.01);
                //     return fee.toFixed(5);
                // }
                return 0;
            },
            getCoinInfo(index, id) {
                const params = {
                    'vs_currency': 'usd',
                    'localization': false,
                    'sparkline': false
                };

                if (index == 0)
                    axios.get('https://api.coingecko.com/api/v3/coins/'+id, {params})
                        .then(response => ( this.localData0 = response.data))
                        .catch(error => console.log(error))
                        .finally(() => this.localLoading = false)
                else
                    axios.get('https://api.coingecko.com/api/v3/coins/'+id, {params})
                        .then(response => ( this.localData1 = response.data))
                        .catch(error => console.log(error))
                        .finally(() => this.localLoading = false)
            },
            formatPrice(index) {
                let marketValue;
                let price;
                if (index == 0)
                    marketValue = this.localData0;
                else if (index == 1)
                    marketValue = this.localData1;

                if (index == 0 && this.coinOne.symbol == "STAR" 
                    || index == 1 && this.coinTwo.symbol == "STAR") {
                    price = this.getDecimalData(BigNumber(token_list[2].price));
                    this.marketPrice[index] = token_list[2].price;
                    return price + " $"
                }

                if (marketValue.market_data == null) {
                    price = "0.00"
                    this.marketPrice[index] = 0;
                }
                else {
                    this.marketPrice[index] = marketValue.market_data.current_price.usd;
                    price = this.getDecimalData(BigNumber(marketValue.market_data.current_price.usd));
                }

                return price + " $"
            },
            format24hPrice(index) {
                let marketValue;
                if (index == 0)
                    marketValue = this.localData0;
                else if (index == 1)
                    marketValue = this.localData1;

                if (index == 0 && this.coinOne.symbol == "STAR" 
                    || index == 1 && this.coinTwo.symbol == "STAR") {
                    let value = this.$store.state.liquidity.pairInfo[0].pair.priceChange.h24;
                    return value;
                }

                if (marketValue.market_data == null)
                    return "0.00"
                else {
                    let value = BigNumber(marketValue.market_data.price_change_percentage_24h).toFormat(2);
                    return value;
                }
            },
            getChartURL(index) {
                let url;
                if (index == 0)
                    url = "https://www.coingecko.com/coins/" + this.chartURL0 + "/sparkline";
                else
                    url = "https://www.coingecko.com/coins/" + this.chartURL1 + "/sparkline";

                return url;
            },
            showBlock(kind) {
                if (kind == 0)
                    this.limitText = 'Minimum received'
                else
                    this.limitText = 'Maximum sold'

                var item = document.getElementById("extraInfo");
                item.className += " is-visible";

                setTimeout(function () {
                    item.className += " is-open";
                }, 150);
            },
            hideBlock() {
                var item = document.getElementById("extraInfo");

                item.classList.remove("is-open");

                setTimeout(function () {
                    item.classList.remove("is-visible");
                }, 150);
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            },
            getPriceImpact() {
                if (this.valueOne > 0) {
                    let value;
                    if (this.$store.state.liquidity.crossOneRouter || this.$store.state.liquidity.crossTwoRouter) {
                        let value0 = this.valueOne;
                        let value1 = this.valueTwo;

                        let currentPrice = Number(value0) / Number(value1);
                        let marketPlace = Number(this.marketPrice[1].toString()) / Number(this.marketPrice[0]);
                        let diff = Math.abs(Number(currentPrice - marketPlace));

                        value = BigNumber(diff * 100 / marketPlace);
                    }
                    else {
                        let decimals0 = this.coinOne.decimals * (-1);
                        let reservedLiquidity = BigNumber(this.$store.state.liquidity.amountOne)
                                .shiftedBy(decimals0);
                        let amountInWithFee  = this.valueOne * (1 - 0.003);

                        value = BigNumber(amountInWithFee).times(100)
                            .div(BigNumber(reservedLiquidity).plus(BigNumber(amountInWithFee)));
                    }

                    if (BigNumber(value).isGreaterThan(BigNumber(3))) {
                        this.warningPriceImpact = true;
                    }
                    else {
                        this.warningPriceImpact = false;
                    }

                    return this.getDecimalData(value);
                }
                else
                    return 0;
            }
        }
    };
</script>

<style scoped>
    .mb-3, .my-3 {
        margin-bottom: 0.1rem!important;
    }

    .cvEFMc {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 0.2rem;
        border: none;
        /* background: none rgb(30, 41, 54); */
        outline: none;
        cursor: default;
        border-radius: 36px;
        color: rgb(152, 163, 175);
    }

    #extraInfo {
        display: none;
        opacity: 0;
        transition: all .3s linear;
        padding:10px !important;
    }

    .is-visible {
        display: block !important;
    }

    .is-open {
        margin-top: 15px !important;
        opacity: 1 !important;
    }

    .tooltip1 {
        position: relative;
        display: inline-block;
    }

    .tooltip1 p {
        font-weight: 500;
        color:rgba(255,255,255,0.7) !important;
    }
    .tooltip1 .tooltiptext {
        visibility:hidden;
        background-color: #131a35;
        border-radius: 7px;
        padding: 15px;
        position: absolute;
        z-index: 1;
        top: -53px;
        left: 30px;
        width:190px;
    }

    .tooltip1 .kind1 {
        visibility:hidden;
        top: -36px;
        left: 30px;
        width:190px;
    }
    .tooltip1 .tooltiptext::after {
        content: "";
        position: absolute;/* To position the tooltip */
        top: 40%;
        left: 0%;
        margin-left: -15px;

        /* To add an arrow */
        border: 8px solid;
        border-color: #131a35 transparent transparent transparent;
        transform: rotate(90deg);
    }
    .tooltip1:hover .tooltiptext {
        visibility: visible;
    }

    .crossBox {
        font-size:13px;
        color:#abc4ed;
        font-weight:500
    }

    .crossBox img {
        width:20px;
    }

@media(max-width: 996px) {
    /* .crossBox {
        font-size:12px;
        color:#abc4ed;
        font-weight:500
    }

    .crossBox img {
        width:15px;
    } */
}
</style>
