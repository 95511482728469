<template>
  <section id="workingArea">
      <div class="swapBtn d-flex  align-items-center">
            <router-link :to="getSwapURL" class="ml-1 btn1">
              Swap
            </router-link>
            <router-link :to="getLiquidityURL">
              Liquidity
            </router-link>
      </div>
      <div>
          <Swap :token0="token0" :token1="token1" @set-fee="fee = $event" />
      </div>
  </section>
</template>
<script>
    import Swap from '@/components/currency/Swap.vue';
    import { BigNumber } from 'bignumber.js'

    export default {
        components: {
            Swap
        },
        data() {
            return {
                fee: 0
            }
        },
        computed: {      
            token0() {
                if (this.$route.params.token0 == null)
                    return "ASTR";

                return this.$route.params.token0
            },
            token1() {
                if (this.$route.params.token1 == null)
                    return "STAR";

                return this.$route.params.token1
            },
            getLiquidityURL() {
                return "/liquidity/0/" + this.$store.state.liquidity.coinOne.symbol + "/" + this.$store.state.liquidity.coinTwo.symbol
            },
            getSwapURL() {
                return "/swap/" + this.$store.state.liquidity.coinOne.symbol + "/" + this.$store.state.liquidity.coinTwo.symbol
            },
        },
    }
</script>